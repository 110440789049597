import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../components/font-awesome"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CenterPage = ({ data: { strapiCenter } }) => {
  return (
    <Layout>
      <SEO title={`${strapiCenter.title} - ICDA`} />
      <section className="mb-3">
        <div
          className="w-100 d-flex bg-light inst-img"
          style={{
            backgroundImage: strapiCenter.headerImage.image.url
              ? `url(${strapiCenter.headerImage.image.url})`
              : "",
          }}
        >
          <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
            <h2 className="mb-4 fw-bold text-uppercase title">Centros</h2>
          </div>
        </div>
        <div className="container">
          <div className="my-4">
            <h1>{strapiCenter.title}</h1>
            <div className="d-flex ml-auto my-4">
              <Link
                to=""
                className="btn btn-social mr-1 rounded-circle rounded-circle btn-social-icon btn-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </Link>
              <Link
                to=""
                className="btn btn-social mr-1 rounded-circle rounded-circle btn-social-icon btn-twitter"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </Link>
              <Link
                to=""
                className="btn btn-social mr-1 rounded-circle rounded-circle btn-social-icon btn-linkedin"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </Link>
              <Link
                to=""
                className="btn btn-social mr-1 rounded-circle rounded-circle btn-social-icon btn-instagram"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </Link>
            </div>
            <div className="border-top border-bottom p-4">
              <ReactMarkdown source={strapiCenter.summary} />
            </div>
            <div className="pt-4">
              <ReactMarkdown source={strapiCenter.content} />
            </div>

            <div className="bg-sec text-white p-4 mt-5">
              <ReactMarkdown source={strapiCenter.destacado} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CenterPage

export const mastersQuery = graphql`
  query($id: String) {
    strapiCenter(id: { eq: $id }) {
      content
      title
      summary
      created_at
      destacado
      headerImage {
        title
        image {
          url
        }
      }
      title
      summary
      name
    }
  }
`
